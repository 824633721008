import type { ItemModel, RecordSourceFieldType, ILayoutModuleOptions } from 'o365-dataobject';
import { LayoutModule } from 'o365-dataobject';
import { isGroupByLevelConfiguration } from './DataObject.Configurations.GroupBy.ts';
import { isHierarchyLevelConfiguration } from './DataObject.Configurations.Hierarchy.ts';

type NodeDataLayoutConfiguration = {
    type: 'hierarchy'
    idField?: string;
    parentField?: string;
    idPathField?: string;
} | {
    type: 'groupBy',
    fieldName?: string;
    fields?: string[];
}

type NodeDataLayoutValue = {
    aggregates?: {
        name: string,
        aggregate: NonNullable<RecordSourceFieldType['aggregate']>,
    }[],
    configurations?: NodeDataLayoutConfiguration[]
}


export class NodeDataLayoutModule<IT extends ItemModel = ItemModel> extends LayoutModule<IT, NodeDataLayoutValue> {

    constructor(pOptions: ILayoutModuleOptions<IT>, pInitialValue: NodeDataLayoutValue, pModuleOptions: {
        baseline?: NodeDataLayoutValue
    }, _pParentValue?: NodeDataLayoutValue, pStoredChanges?: NodeDataLayoutValue) {
        super('nodeData', pOptions);
        if (pModuleOptions.baseline) {
            this._baseline = pModuleOptions.baseline;
        }

        if (pInitialValue || pStoredChanges) {
            this.apply(pStoredChanges || pInitialValue, !!pStoredChanges);
            const dataObject = this.getDataObject();
            if (dataObject.state.isLoaded || dataObject.state.isLoading) {
                if (dataObject.state.loadingPromise) {
                    dataObject.state.loadingPromise.then(() => {
                        dataObject.load();
                    });
                } else {
                    dataObject.load();
                }
            }
        }
    }

    apply(pValue?: NodeDataLayoutValue, pSkipValueSet = false) {
        const dataObject = this.getDataObject();
        if (!dataObject.hasNodeData) { return; }
        if (pValue == null || pValue.configurations == null) {
            this.reset();
            return;
        }
        dataObject.nodeData.removeAllConfigurations();
        for (const config of pValue.configurations) {
            if (config.type == 'groupBy') {
                dataObject.nodeData.addConfiguration({
                    type: 'groupBy',
                    fieldName: config.fieldName,
                    fields: config.fields,
                });
            } else {
                dataObject.nodeData.addConfiguration({
                    type: 'hierarchy',
                    idField: config.idField,
                    parentField: config.parentField,
                    idPathField: config.parentField,
                });
            }
        }
        // const dataGridControl = this.getSharedControl('dataGridControl');
        // if (dataGridControl == null) { return; }
        // dataObject.nodeData.setGroupBy(pValue?.groupBy ?? []);
        // dataGridControl.nodeData.updateNodeColumnState();
        // dataGridControl.nodeData.runAfterLayoutApplyTasks();
        if (!pSkipValueSet) {
            this._value = pValue;
        }
    }

    getValue() {
        return this._value;
    }

    getValueForSave(): NodeDataLayoutValue | undefined {
        const configs = this.getCurrentConfigurations();
        if (configs.length) {
            return {
                configurations: configs
            }
        }
        return undefined;
    }

    hasChanges() {
        const newValue = this.getValueForSave();
        if (newValue && this._value) {
            return JSON.stringify(newValue) !== JSON.stringify(this._value);
        } else {
            return newValue != null || this._value != null;
        }
    }

    mergeValues(pBase: NodeDataLayoutValue, pOverrides: NodeDataLayoutValue) {
        return pOverrides ?? pBase;
    }

    reset() {
        if (this._baseline && JSON.stringify(this._value) !== JSON.stringify(this._baseline)) {
            this.apply(this._baseline);
        } else if (this._value) {
            const dataObject = this.getDataObject();
            if (!dataObject.hasNodeData) { return; }
            const dataGridControl = this.getSharedControl('dataGridControl');
            if (dataGridControl == null) { return; }
            dataObject.nodeData.setGroupBy([]);
            dataGridControl.nodeData.updateNodeColumnState();
            dataGridControl.nodeData.runAfterLayoutApplyTasks();
        }
        this._value = undefined;
    }

    shouldLoadDataObject() { return true; }

    /**
     * Get active NodeData configurations for the layout
     */
    getCurrentConfigurations() {
        const dataObject = this.getDataObject();
        const result: NodeDataLayoutConfiguration[] = [];
        for (const config of dataObject.nodeData.configurations) {
            if (isGroupByLevelConfiguration(config)) {
                result.push({
                    type: 'groupBy',
                    fieldName: config.field,
                    fields: config.fields
                })
            } else if (isHierarchyLevelConfiguration(config)) {
                result.push({
                    type: 'hierarchy',
                    idField: config.idField,
                    parentField: config.parentField,
                    idPathField: config.idPathField
                })
            }
        }
        return result;
    }
}
